import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";

import Tiles from "page_components/Space/Tiles";

const Space = ({
	location,
	pageContext: { translations },
	data: { space, contact },
}) => {
	return (
		<Layout
			seo={{
				title: space.seo.title || space.title,
				description: space.seo.metaDesc || "",
			}}
			translations={translations}
			location={location}
			contact_data={contact?.acfContact}
		>
			<PageHeader label={space?.title || ""} />
			<Tiles data={space?.acfSpace?.spaceContent} />
			{/* <Gallery title="Galeria" data={space?.acfSpace?.gallery} /> */}
		</Layout>
	);
};

export default Space;

export const query = graphql`
	query ($id: Int!) {
		space: wpPage(databaseId: { eq: $id }) {
			title
			seo {
				title
				metaDesc
			}
			acfSpace {
				spaceContent {
					title
					text
					image {
						sourceUrl
					}
				}
				gallery {
					image {
						sourceUrl
					}
				}
			}
		}
		contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
			acfContact {
				email
				phone
				address
			}
		}
	}
`;
