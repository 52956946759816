import "./styles.scss";
import React from "react";

const linePosition = (index) => {
  switch (index) {
    case 1:
      return "left";
    case 2:
      return "right";
    case 3:
      return "top";
    default:
      return "bottom";
  }
};

const Tiles = ({ data }) => {
  let current_index = 0;

  return (
    <section className="space-tiles">
      <div className="container">
        {data.map(({ title, text, image: { sourceUrl } }, index) => {
          if (current_index === 4) {
            current_index = current_index - 4;
          }

          if (index !== 0) current_index++;
          return (
            <div key={index} className="space-tiles__item">
              <div className="space-tiles__item__text">
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <p>{text}</p>
              </div>
              <div
                className={`space-tiles__item__image ${linePosition(
                  current_index
                )}`}
                style={{ backgroundImage: `url(${sourceUrl})` }}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Tiles;
